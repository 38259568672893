import {useNavigate} from "@reach/router"
import React, {useEffect, useState} from "react"
import {paymentAPI} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"

function DefaultPaymentMethodPage({secureCode}) {
	const navigate = useNavigate()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentAPI
			.getDefaultPaymentMethod({secureCode})
			.then((result) => {
				navigate(`/${secureCode}/payment-in-full/${result.DefaultPaymentMethod}`)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [secureCode, navigate])

	return Component
}

export {DefaultPaymentMethodPage}
