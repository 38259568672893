import React, {useEffect, useState} from "react"
import {Typography, Button, Grid} from "@material-ui/core"
import {useNavigate, useParams} from "@reach/router"
import {track} from "@indebted/analytics"
import {paymentAPI} from "@indebted/api"

function PaymentInFullOptionLink({Title, Subtitle, ButtonLabel, RequiresAffordabilityAssessment, Disclaimer}) {
	const {secureCode} = useParams()
	const navigate = useNavigate()
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null)
	useEffect(() => {
		paymentAPI.getDefaultPaymentMethod({secureCode}).then((result) => {
			setDefaultPaymentMethod(result.DefaultPaymentMethod)
		})
	}, [secureCode])

	const redirect = (RequiresAffordabilityAssessment) => {
		if (RequiresAffordabilityAssessment) {
			navigate(`/${secureCode}/affordability-assessment/payment-in-full/initiate`)
		} else {
			navigate(`/${secureCode}/payment-in-full/${defaultPaymentMethod}`)
		}
	}

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4}>
			<Grid item>
				<Typography variant="h6" align="left">
					{Title}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" color="textSecondary" align="left">
					{Subtitle}
				</Typography>
			</Grid>
			<Grid item>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						track("PIFButtonClicked", {SecureCode: secureCode})
						redirect(RequiresAffordabilityAssessment)
					}}
				>
					{ButtonLabel}
				</Button>
			</Grid>
			<Grid item>
				<Typography variant="body2" color="textSecondary" align="left">
					{Disclaimer}
				</Typography>
			</Grid>
		</Grid>
	)
}

export {PaymentInFullOptionLink}
