import {Button, Grid} from "@material-ui/core"
import {RedCrossIcon, ReoccurringDollarIcon} from "@indebted/assets"
import {colors} from "@indebted/theme"
import React from "react"
import {useNavigate, useParams} from "@reach/router"

import {ColoredTypographyWithBottomMargin} from "./styles"

const PaymentFailedPage = ({I18n, reason}) => {
	const {paymentPlanID} = useParams()
	const navigate = useNavigate()
	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginTop: "25px"}}
			>
				<RedCrossIcon />
			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "10px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="h5"
					color={colors.stratosLight2}
					align="center"
					style={{padding: "0 30px", fontWeight: 700, lineHeight: "28px"}}
				>
					{I18n.PaymentFailed.Title}
				</ColoredTypographyWithBottomMargin>
			</Grid>

			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "10px"}}
			>
				{reason && (
					<ColoredTypographyWithBottomMargin
						variant="subtitle1"
						color={colors.stratos}
						align="center"
						style={{padding: "0 30px", lineHeight: "22px", fontSize: 16}}
					>
						{I18n.PaymentFailed.ReasonLabel}: {reason}
					</ColoredTypographyWithBottomMargin>
				)}
			</Grid>

			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "34px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="subtitle1"
					color={colors.stratos}
					align="center"
					style={{padding: "0 64px", lineHeight: "16px", fontSize: 14}}
				>
					{I18n.PaymentFailed.Subtitle}
				</ColoredTypographyWithBottomMargin>
			</Grid>

			<Button
				style={{borderRadius: "35px", padding: "6px 30px", marginTop: "10px"}}
				type="button"
				onClick={() => {
					navigate(`./${paymentPlanID}`)
					navigate(0)
				}}
				variant="contained"
				startIcon={<ReoccurringDollarIcon />}
			>
				{I18n.PaymentFailed.ButtonText}
			</Button>
		</Grid>
	)
}

export {PaymentFailedPage}
