import {Button, Grid} from "@material-ui/core"
import {colors} from "@indebted/theme"
import React from "react"
import {useNavigate, useParams} from "@reach/router"
import {paymentPlanAPI} from "@indebted/api"
import {NotEligible} from "@indebted/assets"

import {
	ColoredTypography,
	ColoredTypographyWithBottomMargin,
	BulletList,
	GridWithPadding,
	DiscountStyledCard,
} from "./styles"

const UpdatePlanInformation = ({I18n, HasPaymentPlanOffer}) => {
	const {paymentPlanID} = useParams()
	const navigate = useNavigate()

	const onSetupNewPlanClick = () => {
		paymentPlanAPI
			.cancel(paymentPlanID)
			.then((result) => {
				if (result.RequiresAffordabilityAssessment) {
					navigate(`/${result.SecureCode}/affordability-assessment/payment-plan/initiate`)
				} else {
					navigate(`/${result.SecureCode}/payment-plan`)
				}
			})
			.catch(() => {
				// TODO:
			})
	}

	return (
		<GridWithPadding container direction="column" alignItems="center" justifyContent="center">
			{HasPaymentPlanOffer && (
				<Grid
					item
					container
					direction="column"
					alignItems="flex-start"
					justifyContent="flex-start"
					style={{marginTop: "0px"}}
				>
					<DiscountStyledCard
						isEligible={HasPaymentPlanOffer}
						variant="outlined"
						style={{display: "flex", padding: "16px", gap: "6px"}}
					>
						<Grid direction="row">
							<NotEligible />
						</Grid>
						<Grid direction="row">
							<ColoredTypography
								color={colors.stratos}
								align="left"
								style={{fontSize: "14px", lineHeight: "20px"}}
							>
								{I18n.ManagePaymentPlan.UpdatePaymentPlanConfirmationPage.DiscountBanner}
							</ColoredTypography>
						</Grid>
					</DiscountStyledCard>
				</Grid>
			)}

			<Grid
				item
				container
				direction="row"
				alignItems="flex-start"
				justifyContent="flex-start"
				style={{marginBottom: "16px", marginTop: "20px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="h5"
					color={colors.stratos}
					align="left"
					style={{fontWeight: 600, fontSize: "18px", lineHeight: "22px"}}
				>
					{I18n.ManagePaymentPlan.UpdatePaymentPlanConfirmationPage.Title}
				</ColoredTypographyWithBottomMargin>
			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="flex-start"
				justifyContent="flex-start"
				style={{marginBottom: "10px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="subtitle1"
					color={colors.stratos}
					align="left"
					style={{fontSize: "14px", lineHeight: "20px"}}
				>
					{I18n.ManagePaymentPlan.UpdatePaymentPlanConfirmationPage.SubTitle}
				</ColoredTypographyWithBottomMargin>
				<br />

				<BulletList color={colors.stratos}>
					{I18n.ManagePaymentPlan.UpdatePaymentPlanConfirmationPage.BulletPoints.map((item, idx) => (
						<li key={idx}>{item}</li>
					))}
				</BulletList>
			</Grid>

			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "34px"}}
			>
				<Button
					style={{
						borderRadius: "4px",
						padding: "6px 16px",
						width: "100%",
						backgroundColor: colors.warningButtonRed,
					}}
					type="button"
					onClick={onSetupNewPlanClick}
					variant="contained"
				>
					{I18n.ManagePaymentPlan.UpdatePaymentPlanConfirmationPage.ContinueButtonLabel}
				</Button>
				<Button
					style={{borderRadius: "35px", padding: "6px 30px", marginTop: "10px"}}
					type="button"
					onClick={() => {
						navigate(`./${paymentPlanID}`)
						navigate(0)
					}}
					variant="text"
				>
					{I18n.ManagePaymentPlan.UpdatePaymentPlanConfirmationPage.BackButtonLabel}
				</Button>
			</Grid>
		</GridWithPadding>
	)
}

export {UpdatePlanInformation}
