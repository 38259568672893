import {Button, Grid} from "@material-ui/core"
import {ErrorIcon} from "@indebted/assets"
import {colors} from "@indebted/theme"
import React from "react"
import {useNavigate, useParams} from "@reach/router"

import {ColoredTypographyWithBottomMargin} from "./styles"

const ChargeInitiateFailedPage = ({I18n}) => {
	const {paymentPlanID} = useParams()
	const navigate = useNavigate()

	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{margin: "30px 0px"}}
			>
				<ErrorIcon />
			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "10px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="h5"
					color={colors.stratosLight2}
					align="center"
					style={{padding: "0 64px", fontWeight: 700, lineHeight: "28px"}}
				>
					{I18n.ManagePaymentPlan.InstalmentDueStatusGeneralErrorTitle}
				</ColoredTypographyWithBottomMargin>
			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "34px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="subtitle1"
					color={colors.stratos}
					align="center"
					style={{padding: "0 64px", lineHeight: "22px"}}
				>
					{I18n.ManagePaymentPlan.InstalmentDueStatusGeneralErrorSubtitle}
				</ColoredTypographyWithBottomMargin>
			</Grid>

			<Button
				style={{borderRadius: "35px", padding: "6px 30px", marginTop: "10px"}}
				type="button"
				onClick={() => {
					navigate(`./${paymentPlanID}`)
					navigate(0)
				}}
				variant="contained"
			>
				{I18n.ManagePaymentPlan.InstalmentDueStatusGeneralErrorButtonText}
			</Button>
		</Grid>
	)
}

export {ChargeInitiateFailedPage}
