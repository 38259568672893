import React from "react"
import {Typography, Button, Grid} from "@material-ui/core"
import {useNavigate, useParams} from "@reach/router"
import {track} from "@indebted/analytics"

function PartialPaymentOptionLink({Title, Subtitle, ButtonLabel, Disclaimer}) {
	const {secureCode} = useParams()
	const navigate = useNavigate()

	track("PartialPaymentOptionOffered", {SecureCode: secureCode})

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4}>
			<Grid item>
				<Typography variant="h6" align="left">
					{Title}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" color="textSecondary" align="left">
					{Subtitle}
				</Typography>
			</Grid>
			<Grid item>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						track("PartialPaymentButtonClicked", {SecureCode: secureCode})
						navigate(`/${secureCode}/partial-payment`)
					}}
				>
					{ButtonLabel}
				</Button>
			</Grid>
			<Grid item>
				<Typography variant="body2" color="textSecondary" align="left">
					{Disclaimer}
				</Typography>
			</Grid>
		</Grid>
	)
}

export {PartialPaymentOptionLink}
