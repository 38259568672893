import React, {useState} from "react"
import {Button, Grid, Typography} from "@material-ui/core"
import {DiscountMessage} from "@indebted/components/DiscountMessage"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {useNavigate, useParams} from "@reach/router"
import styled from "styled-components"
import {colors} from "@indebted/theme"

const FormConfirmation = ({onSubmit, i18n, discountMessage, paymentMethod}) => {
	const {secureCode} = useParams()
	const [disabled, setDisabled] = useState(false)
	const navigate = useNavigate()

	function submit(e) {
		e.preventDefault()
		onSubmit()
	}

	const cancelButtonClicked = (event) => {
		event.preventDefault()

		setDisabled(true)

		navigate(`/${secureCode}/payment-plan`)
	}

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<DiscountMessage discountMessage={discountMessage} />
				</Grid>
				<Grid item>
					<ColoredTypography color={colors.stratosLight2} variant="h6">
						{i18n.EFTADisclosureTitle}
					</ColoredTypography>
				</Grid>
				<Grid item>
					<ColoredTypography color={colors.stratosLight2} style={{fontSize: "14px", fontWeight: 400}}>
						<TextToHTML text={i18n.EFTADisclosureText} />
					</ColoredTypography>
				</Grid>
				<Grid item>
					{i18n.ConfirmationDisclaimer.map((disclaimer, i) => (
						<>
							<ColoredTypography
								color={colors.stratosLight2}
								key={i}
								style={{fontSize: "14px", fontWeight: 400}}
							>
								<TextToHTML
									component="p"
									align="justify"
									text={disclaimer
										?.replace(
											"${account_number_last_four_digits}",
											paymentMethod.us_bank_account.last4,
										)
										?.replace("${routing_number}", paymentMethod.us_bank_account.routing_number)}
								></TextToHTML>
							</ColoredTypography>
							<br />
						</>
					))}
				</Grid>
				<Grid item>
					<Button fullWidth type="submit">
						{i18n.EFTADisclosurePrimaryButtonLabel}
					</Button>
				</Grid>
				{i18n.EFTADisclosureSecondaryButtonLabel && (
					<Grid item>
						<Button fullWidth variant="outlined" onClick={cancelButtonClicked} disabled={disabled}>
							{i18n.EFTADisclosureSecondaryButtonLabel}
						</Button>
					</Grid>
				)}
				{i18n.EFTADisclosureAgreementText && (
					<Grid item>
						<ColoredTypography color={colors.stratosLight2} style={{fontSize: "14px", fontWeight: 400}}>
							<TextToHTML
								text={i18n.EFTADisclosureAgreementText?.replace(
									"${account_number_last_four_digits}",
									paymentMethod.us_bank_account.last4,
								)?.replace("${routing_number}", paymentMethod.us_bank_account.routing_number)}
							/>
						</ColoredTypography>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

const ColoredTypography = styled(({color, ...props}) => <Typography {...props} />)`
	color: ${(props) => props.color};
`

export {FormConfirmation}
