import React, {useEffect, useState} from "react"
import {Router, useNavigate} from "@reach/router"
import {LoadingPage} from "@indebted/components/Loading"
import {paymentPlanAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"

import {FrequencySelection} from "./FrequencySelection"
import {WeeklyPayDay} from "./DateSelection/WeeklyPayDay"
import {FortnightlyPayDay} from "./DateSelection/FortnightlyPayDay"
import {FortnightlyNextPayDate} from "./DateSelection/FortnightlyNextPayDate"
import {MonthlyPayDay} from "./DateSelection/MonthlyPayDay"
import {CalendarCustomStartDate} from "./DateSelection/CustomStartDate"
import {AmountSelectionPage} from "./AmountSelection"
import {PaymentMethodSelection} from "./PaymentMethodSelection"
import {PaymentForm} from "./PaymentForm"

function PaymentPlanForm({options}) {
	const frequencyByID = (ID) => options.FrequencyOptions.find((f) => f.ID === ID)
	const navigate = useNavigate()
	return (
		<>
			<Router>
				<FrequencySelection
					i18n={options.I18n.FrequencySelection}
					frequencies={options.FrequencyOptions}
					navigate={navigate}
					path="/"
				/>
				<WeeklyPayDay
					path="/Weekly"
					frequency={frequencyByID("Weekly")}
					i18n={{DateSelection: options.I18n.DateSelection, PayToday: options.I18n.PayToday}}
					todayStartDateEnabled={options.TodayStartDateEnabled}
					navigate={navigate}
				/>
				<FortnightlyPayDay
					path="/Fortnightly"
					frequency={frequencyByID("Fortnightly")}
					i18n={{DateSelection: options.I18n.DateSelection, PayToday: options.I18n.PayToday}}
					todayStartDateEnabled={options.TodayStartDateEnabled}
					navigate={navigate}
				/>
				<FortnightlyNextPayDate
					path="/FortnightlyNextPayDate"
					frequency={frequencyByID("Fortnightly")}
					i18n={options.I18n.DateSelection}
					navigate={navigate}
				/>
				<MonthlyPayDay
					path="/Monthly"
					frequency={frequencyByID("Monthly")}
					i18n={{DateSelection: options.I18n.DateSelection, PayToday: options.I18n.PayToday}}
					todayStartDateEnabled={options.TodayStartDateEnabled}
					navigate={navigate}
				/>
				<CalendarCustomStartDate
					path="/CustomStartDate"
					i18n={options.I18n.DateSelection}
					locale={options.Locale}
					frequencies={options.FrequencyOptions}
					startDateDelay={options.MaxStartDateDelay}
					navigate={navigate}
				/>
				<AmountSelectionPage path="/Amount" frequencyOptions={options.FrequencyOptions} navigate={navigate} />
				<PaymentMethodSelection
					i18n={options.I18n.PaymentMethodSelection}
					path="/PaymentMethodSelection"
					paymentMethods={options.PaymentMethodOptions}
					navigate={navigate}
				/>
				<PaymentForm path="/:paymentMethod" navigate={navigate} />
			</Router>
		</>
	)
}

function PaymentPlanPage({secureCode}) {
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentPlanAPI
			.createForm({secureCode})
			.then((result) => {
				setComponent(<PaymentPlanForm options={result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [secureCode])

	return Component
}

export {PaymentPlanPage}
