import React, {useEffect, useState} from "react"
import {affordabilityAssessmentAPI} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {useNavigate} from "@reach/router"

import {Accepted} from "./Accepted"
import {Rejected} from "./Rejected"
import {Invalid} from "./Invalid"
import {AcceptedWithSuggestions} from "./AcceptedWithSuggestions"

function Result({secureCode, id}) {
	const navigate = useNavigate()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		const components = {
			Accepted: Accepted,
			AcceptedWithSuggestions: AcceptedWithSuggestions,
			Rejected: Rejected,
		}
		affordabilityAssessmentAPI.findBy({secureCode, id}).then((result) => {
			const Component = components[result.Status] || Invalid
			setComponent(<Component {...result} navigate={navigate} secureCode={secureCode} id={id} />)
		})
	}, [secureCode, id, navigate])

	return Component
}

export {Result}
